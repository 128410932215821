import React from 'react'

const GooglePlay = (props) => (
  <svg width='18px' height='20px' viewBox='0 0 18 20' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Main-landing-collapsed-2' transform='translate(-35.000000, -817.000000)' fillRule='nonzero'>
        <g id='Pitch' transform='translate(16.000000, 613.000000)'>
          <g id='Action' transform='translate(0.000000, 194.000000)'>
            <g id='google-play' transform='translate(19.000000, 10.000000)'>
              <path d='M0,18.5 L0,1.5 C0,0.91 0.34,0.39 0.84,0.15 L10.69,10 L0.84,19.85 C0.34,19.6 0,19.09 0,18.5 L0,18.5 Z' id='Path' fill='currentColor' />
              <polygon id='Path' fill='currentColor' points='13.81 13.12 3.05 19.34 11.54 10.85' />
              <path d='M17.16,8.81 C17.5,9.08 17.75,9.5 17.75,10 C17.75,10.5 17.53,10.9 17.18,11.18 L14.89,12.5 L12.39,10 L14.89,7.5 L17.16,8.81 L17.16,8.81 Z' id='Path' fill='currentColor' />
              <polygon id='Path' fill='currentColor' points='3.05 0.66 13.81 6.88 11.54 9.15' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default GooglePlay
